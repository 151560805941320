const status = {
    SampleCtrl : [
        {value: "키트회수", label:"키트회수"},
        {value: "DNA Prep", label:"DNA Prep"},
        {value: "DNA Prep - QC Fail", label:"DNA Prep - QC Fail"},
        {value: "Library Prep", label:"Library Prep"},
        {value: "Library Prep - QC Fail", label:"Library Prep - QC Fail"},
        {value: "Exome Capture", label:"Exome Capture"},
        {value: "Exome Capture - QC Fail", label:"Exome Capture - QC Fail"},
        {value: "Sequencing Data Analysis", label:"Sequencing Data Analysis"},
        {value: "Sequencing Data Analysis - QC Fail", label:"Sequencing Data Analysis - QC Fail"},
        {value: "보고서 생성", label:"보고서 생성"},
        {value: "보고서 생성 완료", label:"보고서 생성 완료"},
        {value: "발행완료", label:"발행완료"},
        {value: "재분석", label:"재분석"},
        {value: "검체 재요청", label:"검체 재요청"},
    ],
    DNAPrep : [
        {value: "DNA Prep 완료", label:"DNA Prep 완료"},
        {value: "DNA 재추출", label:"DNA 재추출"},
        {value: "검체 재요청", label:"검체 재요청"},
    ],
    LibraryPrep : [
        {value: "Library Prep 완료", label:"Library Prep 완료"},
        {value: "Library prep 재작업", label:"Library prep 재작업"},
        {value: "검체 재요청", label:"검체 재요청"},
    ],
    LibraryPrepQCPass : [
        {value: "Library Prep 완료", label:"Library Prep 완료"},
    ],
    LibraryPrepQCFail : [
        {value: "Library prep 재작업", label:"Library prep 재작업"},
        {value: "검체 재요청", label:"검체 재요청"},
    ],
    ExomeCapture : [
        {value: "Exome Capture 완료", label:"Exome Capture 완료"},
        {value: "Library prep 재작업", label:"Library prep 재작업"},
        {value: "Exome Capture 재작업", label:"Exome Capture 재작업"},
        {value: "검체 재요청", label:"검체 재요청"},
    ],
    ExomeCaptureQCPass : [
        {value: "Exome Capture 완료", label:"Exome Capture 완료"},
    ],
    ExomeCaptureQCFail : [
        {value: "Library prep 재작업", label:"Library prep 재작업"},
        {value: "Exome Capture 재작업", label:"Exome Capture 재작업"},
        {value: "검체 재요청", label:"검체 재요청"},
    ],
    Sequencing : [
        {value: "보고서 생성", label:"보고서 생성"},
        {value: "Library prep 재작업", label:"Library prep 재작업"},
        {value: "Exome Capture 재작업", label:"Exome Capture 재작업"},
        {value: "재분석", label:"재분석"},
        {value: "검체 재요청", label:"검체 재요청"},
    ],
    SequencingQCPass : [
        {value: "보고서 생성", label:"보고서 생성"},
    ],
    SequencingQCFail : [
        {value: "Library prep 재작업", label:"Library prep 재작업"},
        {value: "Exome Capture 재작업", label:"Exome Capture 재작업"},
        {value: "재분석", label:"재분석"},
        {value: "검체 재요청", label:"검체 재요청"},
    ],
    QC : [
        {value: "PASS", label:"Pass"},
        {value: "FAIL", label:"Fail"},
    ],
    Report : [
        {value: "검체 재요청", label:"검체 재요청"},
        {value: "Report generation", label:"Report generation"},
        {value: "Report complete", label:"Report complete"},
    ],
    Service : [
        {value: "Service", label:"Service"},
        {value: "Research", label:"Research"},
        {value: "PT", label:"PT"},
        {value: "Test", label:"Test"},
    ],
    gender: [
        {value: "M", label:"남성"},
        {value: "F", label:"여성"},
    ],
    SampleType: [
        {value: "Saliva", label:"Saliva"},
        {value: "Swab", label:"Swab"},
        {value: "Blood", label:"Blood"},
        {value: "DNA", label:"DNA"},
        {value: "기타", label:"기타"}
    ]

};
module.exports = status;
